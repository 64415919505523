'use client'

import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  InputHTMLAttributes,
} from 'react'
import styles from './TextInput.module.css'
import classNamesBind from 'classnames/bind'
const cn = classNamesBind.bind(styles)

export interface OwnProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  value?: InputHTMLAttributes<HTMLInputElement>['value']
  label?: string
  helperText?: string
  disabled?: boolean
  error?: boolean
  placeholder?: string
  noMargin?: boolean
  type?: string
  secondary?: boolean
}

const TextInput: FC<OwnProps> = ({
  className = '',
  onChange,
  onFocus,
  onBlur,
  value,
  label,
  helperText,
  disabled,
  error,
  placeholder,
  noMargin,
  type,
  secondary = false,
  ...inputProps
}) => {
  return (
    <div className={cn('container', { disabled }, { error }, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <input
        className={cn('input', { secondary }, { noMargin })}
        type={type || 'text'}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        data-testid="text-input"
        {...inputProps}
      />
      {helperText && <div className={styles.helperText}>{helperText}</div>}
    </div>
  )
}

export default TextInput
